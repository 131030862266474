import * as React from 'react';
import {
  Text,
  Box,
  TouchableBox,
  TColorTokensNames,
  TColorTokens,
} from '@youtoken/ui.primitives';
import {Icon, BaseIconName} from '@youtoken/ui.icons';

const getActionColors = (
  withAccent: boolean,
  disabled: boolean
): {
  bgColor: TColorTokensNames;
  iconColor: keyof TColorTokens;
  textColor: TColorTokensNames;
} => {
  return {
    bgColor: disabled
      ? '$ui-01'
      : withAccent
      ? '$interactive-01'
      : '$interactive-02',
    iconColor: disabled ? '$text-03' : withAccent ? '$text-04' : '$text-05',
    textColor: disabled ? '$text-03' : '$text-05',
  };
};

export type ActionItemProps = {
  title: string;
  iconName: BaseIconName;
  withAccent: boolean;
  disabled: boolean;
  onPress: () => void;
};

export const ActionItem: React.FC<ActionItemProps> = ({
  title,
  iconName,
  withAccent,
  disabled,
  onPress,
}) => {
  const {bgColor, iconColor, textColor} = getActionColors(withAccent, disabled);

  return (
    <TouchableBox
      px={4}
      onPress={onPress}
      disabled={disabled}
      alignItems="center"
      testID={`ACTIONS_BAR_ITEM_${title.toUpperCase()}`}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        width={56}
        height={56}
        borderRadius={28}
        backgroundColor={bgColor}
        mb={8}
      >
        <Icon name={iconName} size={24} color={iconColor} />
      </Box>
      <Text variant="$body-01" color={textColor} textAlign="center">
        {title}
      </Text>
    </TouchableBox>
  );
};
