import {
  type IReactionDisposer,
  type IObservableArray,
  action,
  runInAction,
  remove,
  computed,
  observable,
  reaction,
} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {SENTRY} from '@youtoken/ui.sentry';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {
  type NotificationPayloadDesignKind,
  type NotificationPayloadDesignVariant,
  type NotificationPayload,
  type NotificationPayloadLevel,
  NotificationScopes,
  NotificationType,
  NotificationDesign,
  Notification,
  NotificationCategory,
} from './NotificationsResponse';
import {LOCAL_MODAL_NOTIFICATIONS} from '@youtoken/ui.local-modal-notifications';
import {handleGeneralError} from '@youtoken/ui.validation-messages';
import {getModalNotification} from './utils';

export {
  type NotificationPayloadDesignKind,
  type NotificationPayloadDesignVariant,
  type NotificationPayload,
  type NotificationPayloadLevel,
  NotificationScopes,
  NotificationType,
  NotificationDesign,
  Notification,
};

export class NotificationsResource extends createResource({
  getKey: () => `notifications`,
  getData: () =>
    TRANSPORT.API.get('/v1/notifications')
      .then(response => {
        return deserialize(Notification, response.data as any[]);
      })
      .catch(error => {
        SENTRY.capture(error, {
          source: 'NotificationsResource',
        });
        return [] as Notification[];
      }),
  cacheTime: 24 * 60 * 60 * 1000,
  skipRefreshOnVisible: false,
}) {
  static initialScope?: NotificationScopes;

  @observable
  scope?: NotificationScopes;

  @observable
  disposers: IReactionDisposer[] = [];

  @action
  setScope = (scope?: NotificationScopes) => {
    this.scope = scope;
  };

  @computed
  get topBar() {
    return this.data.filter(
      ({category}) => category === NotificationCategory.TOP_BAR
    );
  }

  @computed
  get carousel() {
    return this.data.filter(
      ({category}) => category === NotificationCategory.CAROUSEL
    );
  }

  @computed
  get minerPromo() {
    return this.data.filter(
      ({category}) => category === NotificationCategory.WALLET_COIN_INFO
    )?.[0];
  }

  @computed
  get popUps() {
    return getModalNotification(this.data);
  }

  getById = computedFn((id: string) => {
    return this.data.find(({notificationId}) => {
      return id === notificationId;
    });
  });

  getPopUpByScope = computedFn((scope: NotificationScopes) => {
    return this.popUps.find(({conditions}) => {
      return conditions.scopes?.[scope];
    });
  });

  @action
  remove = (id: string) => {
    const notification = this.data.findIndex(({notificationId}) => {
      return id === notificationId;
    });

    if (notification !== -1) {
      remove(this.data as IObservableArray<Notification>, notification);
    }
  };

  @action
  close = (id: string) => {
    return TRANSPORT.API.post('/v1/notifications/close', {
      notificationId: id,
    })
      .then(() => {
        this.remove(id);
      })
      .catch(error => {
        handleGeneralError(error);
        SENTRY.capture(error as Error, {
          source: 'NotificationsResource close',
        });
      });
  };

  @action
  markAsShowed = (id: string) => {
    return TRANSPORT.API.post('/v1/notifications/shown', {
      notificationId: id,
    })
      .then(() => {
        const notification = this.getById(id);

        if (notification) {
          runInAction(() => {
            notification.showed = true;
          });
        }
      })
      .catch(error => {
        SENTRY.capture(error as Error, {
          source: 'NotificationsResource shown',
        });
      });
  };

  subscribeToSocketEvents() {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'notification',
    });
  }

  unsubscribeFromSocketEvents() {
    TRANSPORT.SOCKET.emit('unsub', {
      name: 'notification',
    });
  }

  @action handleSocketCurrentNotifications = ({
    notifications,
  }: {
    notifications: Notification[];
  }) => {
    this.data = deserialize(Notification, notifications);
  };

  constructor(args: {}, data: Notification[]) {
    super(args, data);

    if (NotificationsResource.initialScope) {
      this.scope = NotificationsResource.initialScope;
    }

    this.disposers.push(
      reaction(
        () => {
          if (!this.scope) {
            return undefined;
          }

          return this.getPopUpByScope(this.scope)?.notificationId;
        },
        notificationId => {
          if (notificationId) {
            LOCAL_MODAL_NOTIFICATIONS.request(notificationId);
          }
        },
        {
          fireImmediately: true,
        }
      )
    );

    TRANSPORT.SOCKET.on('connect', this.subscribeToSocketEvents);
    TRANSPORT.SOCKET.on(
      'currentNotifications',
      this.handleSocketCurrentNotifications
    );

    this.subscribeToSocketEvents();
  }

  onDestroy() {
    super.onDestroy();

    this.disposers.forEach(disposer => disposer());

    TRANSPORT.SOCKET.off('connect', this.subscribeToSocketEvents);
    TRANSPORT.SOCKET.off(
      'currentNotifications',
      this.handleSocketCurrentNotifications
    );

    this.unsubscribeFromSocketEvents();
  }
}
