import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {type FirstActionType} from '@youtoken/ui.resource-first-action-perks';
import {deserializeFirstActionPerks} from './FirstActionPerksResponse';

type FirstActionPerksResourceArgs = {
  action: FirstActionType;
};

const ACTIONS_TO_PARAMS: Record<FirstActionType, string> = {
  deposit: 'firstDeposit',
  trade: 'firstTrade',
};

export class FirstActionPerksResource extends createResource({
  getKey: ({action}: FirstActionPerksResourceArgs) => `promo-info/${action}`,
  getData: ({action}: FirstActionPerksResourceArgs) =>
    TRANSPORT.API.get('/v1/promo/info', {
      params: {
        action: ACTIONS_TO_PARAMS[action],
      },
    }).then(({data}) => {
      return deserializeFirstActionPerks(data);
    }),
}) {}
