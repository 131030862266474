import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {
  ConversionPairSourceAuthed,
  ConversionPairTarget,
  FormFooter,
  InfoTableV2,
  TwoFA,
} from '../../..';
import {BuyCryptoFormAuthorizedOnRampState} from '../../../../state';
import {DepositMethodRampEnum} from '../../../../types';
import {getTitle} from '../../utils';
import {CardSelector, HeaderBase} from '../../../../../../components';
import {PayWithField} from '../../../PayWith';
import type {BuyCryptoFormAuthorizedOnRampWizardNavigatorParams} from '..';

export const BuyCryptoFormAuthorizedOnRampMain: React.FC = observer(() => {
  const {navigate, goBack} = useWizard<
    BuyCryptoFormAuthorizedOnRampWizardNavigatorParams,
    'Main'
  >();

  const {
    form: {conversionTicker, depositMethod, cardsList, setCardId, cardId},
  } = useForm<BuyCryptoFormAuthorizedOnRampState>();

  const handlePressManage = React.useCallback(() => {
    navigate('PaymentMethods');
  }, [navigate]);

  const handlePressAddCard = React.useCallback(() => {
    navigate('AddCard');
  }, [navigate]);

  return (
    <>
      <HeaderBase onPressBack={goBack}>{getTitle(conversionTicker)}</HeaderBase>
      <Box flex={1} flexGrow={1} p={24} justifyContent="space-between">
        <Box>
          <ConversionPairSourceAuthed />
          <ConversionPairTarget mt={24} />
          <PayWithField
            onPress={() => {
              navigate('ChooseMethod');
            }}
            mt={24}
          />
          {depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME && (
            <CardSelector
              cardsList={cardsList}
              onSelectCard={setCardId}
              selectedCardId={cardId}
              onPressAddNewCard={handlePressAddCard}
              onPressManage={handlePressManage}
              shouldShowLabel
              mt={24}
            />
          )}
        </Box>
        <Box>
          {depositMethod !== DepositMethodRampEnum.BANK_WIRE && (
            <InfoTableV2 mt={24} />
          )}
          <TwoFA mt={24} />
        </Box>
      </Box>
      <FormFooter
        onPressSubmit={
          depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME &&
          cardsList.length === 0
            ? handlePressAddCard
            : undefined
        }
      />
    </>
  );
});
