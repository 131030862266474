import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {NextFreeSparkIn} from './components';
import {GetMoreSparksModal, SparkPriceBasic} from '..';

export const RightSection: React.FC = observer(() => {
  const {
    overview: {finalSparkBalance: sparkBalance},
    loyalty: {
      data: {currentLevel},
    },
  } = useResources({
    overview: getResourceDescriptor(MinerOverviewResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
  });

  const [isModalOpen, setModalOpen] = React.useState(false);

  const openUpgradeLevelModal = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
  }, []);

  const openGetMoreSparksModal = React.useCallback(() => {
    DATA_LAYER.trackStrict('miner-get-more', {
      category: 'miner',
      type: 'get-more',
    });
    setModalOpen(true);
  }, [setModalOpen]);

  const onModalClose = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const {t} = useTranslation();

  return (
    <Box alignItems="flex-end">
      <Text variant="$body-02" color="$text-02" px={4}>
        {t('surface.miner.overview.sparks')}
      </Text>
      <TouchableBox
        testID="ADD_SPARK_BUTTON"
        borderRadius={24}
        backgroundColor="$attention-02"
        flexDirection="row"
        alignItems="center"
        mt={8}
        p={4}
        pl={8}
        onPress={
          currentLevel < 3 ? openUpgradeLevelModal : openGetMoreSparksModal
        }
      >
        <SparkPriceBasic amount={sparkBalance} />
        <Box
          ml={8}
          width={24}
          height={24}
          borderRadius={12}
          backgroundColor="$attention-01"
          alignItems="center"
          justifyContent="center"
        >
          <Icon name="plus" size={16} color="$text-04" />
        </Box>
      </TouchableBox>

      <NextFreeSparkIn />

      <GetMoreSparksModal
        title={t('surface.upgrade_modal.sparks.title')}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </Box>
  );
});
