import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon, LogoColored} from '@youtoken/ui.icons';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handlePressBtcWallet = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsItem', {ticker: 'btc'});
};

export const LeftSection: React.FC = observer(() => {
  const {totalMinedAmountFormatted, totalMinedTickerFormatted} =
    MinerOverviewResource.use({});

  const {t} = useTranslation();

  return (
    <Box>
      <Text variant="$body-02" color="$text-02" px={4}>
        {t('surface.miner.overview.total')}
      </Text>
      <Box
        testID="TOTAL_MINED_BTC"
        borderRadius={24}
        backgroundColor="$success-03"
        flexDirection="row"
        alignItems="center"
        mt={8}
        p={4}
        pr={16}
      >
        <LogoColored name="btc" size={24} />
        <Text variant="$body-01-high-accent" color="$text-01" ml={6}>
          {totalMinedAmountFormatted}
        </Text>
        <Text variant="$body-01" color="$text-01" ml={2}>
          {totalMinedTickerFormatted}
        </Text>
      </Box>
      <TouchableBox
        testID="BTC_WALLET_LINK"
        mt={8}
        px={4}
        flexDirection="row"
        alignItems="center"
        onPress={handlePressBtcWallet}
      >
        <Text variant="$body-02" color="$text-02" ml={2}>
          {t('surface.miner.balance_link', {ticker: 'BTC'})}
        </Text>
        <Icon name="chevron_right" size={16} color="$text-02" />
      </TouchableBox>
    </Box>
  );
});
