import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {coinNames} from '@youtoken/ui.coin-utils';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {BankCardAddMoneyForm} from '../state';

export const AmountInput: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {ticker, sumField, hasSumError, sumError, onSumChange, maxAmount},
    view: {tickerFormatted},
  } = useForm<BankCardAddMoneyForm>();

  // TODO: This have to be in form!

  const {getByTicker} = WalletsResource.use({});

  const {amountFormatted} = getByTicker(ticker)!;

  const maxSum = React.useMemo(() => {
    return Math.min(Number(amountFormatted), maxAmount.toNumber());
  }, [amountFormatted, maxAmount]);

  //

  const handleAllPress = React.useCallback(() => {
    onSumChange(maxSum);
  }, [maxSum, onSumChange]);

  return (
    <>
      <Box mb={8} flexDirection="row" justifyContent="space-between">
        <Text variant="$body-01-high-accent" testID="FROM_LABEL">
          {t('surface.bank_cards.deposit.field.from')}
        </Text>

        <TouchableBox
          flexDirection="row"
          onPress={handleAllPress}
          testID="MAX_AMOUNT"
        >
          <Text variant="$body-02" color="$text-05" testID="MAX_AMOUNT_LABEL">
            {Number(amountFormatted) > maxAmount.toNumber()
              ? t('surface.bank_cards.deposit.sum.max')
              : t('surface.bank_cards.deposit.sum.all')}
          </Text>
          <Text
            variant="$body-02-medium-accent"
            color="$text-05"
            testID="MAX_AMOUNT_VALUE"
          >
            {` ${maxSum}`}
          </Text>
          <Text variant="$body-02" color="$text-05" testID="MAX_AMOUNT_TICKER">
            {` ${tickerFormatted}`}
          </Text>
        </TouchableBox>
      </Box>

      <TextInput
        value={sumField.get('value')}
        onChangeText={onSumChange}
        placeholder="0.00"
        keyboardType="numeric"
        LeftPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            pl={15}
          >
            <LogoColored name={ticker as LogoColoredIconName} size={20} />

            <Text ml={8} variant="$body-01-medium-accent">
              {coinNames[ticker]}
            </Text>
          </Box>
        }
        RightPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            ml={3}
            pr={15}
          >
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        }
        textAlign="right"
        hasError={hasSumError}
        testID="AMOUNT_INPUT"
      />
      <FieldErrorMessage visible={hasSumError} placement="right">
        {getTranslatedValidationMessage(sumError)}
      </FieldErrorMessage>
    </>
  );
});
