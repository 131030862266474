import React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {invariant} from '@youtoken/ui.utils';
import {Icon, BaseIconName} from '@youtoken/ui.icons';
import {type StepItemStatus} from '@youtoken/ui.resource-onboarding-widget';

const size = 24;

export const FinishedItemIcon: React.FC<{}> = ({}) => {
  return (
    <Box
      width={24}
      height={24}
      borderRadius={12}
      backgroundColor="$success-01"
      justifyContent="center"
      alignItems="center"
    >
      <Icon name="check" size={16} color="$text-04" />
    </Box>
  );
};

export const ItemIcon: React.FC<{iconName: BaseIconName}> = ({iconName}) => {
  return (
    <Box
      width={24}
      height={24}
      borderRadius={12}
      backgroundColor="$ui-01"
      justifyContent="center"
      alignItems="center"
    >
      <Icon name={iconName} size={16} color="$text-02" />
    </Box>
  );
};

type TStep = {
  status: StepItemStatus;
  iconName: BaseIconName;
  label: string;
};

type StepsHorizontalProps = BoxProps & {
  steps: TStep[];
};

export const StepsHorizontal: React.FC<StepsHorizontalProps> = ({
  steps,
  ...boxProps
}) => {
  invariant(steps?.length > 1, `steps should have at least 2 items`);

  return (
    <Box {...boxProps}>
      <Box width="100%" flexDirection="row" alignItems="center" px={8} gap={4}>
        {steps.map(({status, iconName}, index) => {
          const isLast = index === steps.length - 1;
          return (
            <>
              <Box
                key={`${iconName}_${index}`}
                flexDirection="row"
                alignItems="center"
              >
                {status !== 'FINISHED' ? (
                  <ItemIcon iconName={iconName} />
                ) : (
                  <FinishedItemIcon />
                )}
              </Box>
              {!isLast && (
                <Box
                  key={`line_${index}`}
                  flexGrow={1}
                  height={2}
                  backgroundColor={
                    status !== 'FINISHED' ? '$ui-01' : '$success-01'
                  }
                />
              )}
            </>
          );
        })}
      </Box>

      <Box width="100%" flexDirection="row" alignItems="center" px={8} gap={4}>
        {steps.map(({label, status}, index) => {
          const isFirst = index === 0;
          const isLast = index === steps.length - 1;

          return (
            <Box
              key={`${label}_${index}`}
              flexDirection="row"
              alignItems="center"
              mr={isFirst ? size / 2 : 0}
              ml={isLast ? size / 2 : 0}
              flex={isFirst || isLast ? 1 : 2}
              justifyContent={
                isFirst ? 'flex-start' : isLast ? 'flex-end' : 'center'
              }
              mt={8}
            >
              <Text
                color={status === 'ACTIVE' ? '$text-01' : '$text-02'}
                variant="$body-02"
                mb={2}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {label}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
