import * as React from 'react';
import {ScrollView} from 'react-native';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {type TInstrumentsList} from '@youtoken/ui.resource-hodl-tariffs';
import {InstrumentsFilters, InstrumentsSortingTabs} from './components';

type FiltersBarProps = {
  disableFilters?: boolean;
  list?: TInstrumentsList;
};

export const FiltersBar: React.FC<FiltersBarProps & BoxProps> = ({
  disableFilters,
  list,
  ...boxProps
}) => {
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      <Box flexDirection="row" alignItems="center" {...boxProps}>
        {!disableFilters && <InstrumentsFilters list={list} />}
        <InstrumentsSortingTabs list={list} ml={disableFilters ? 0 : 4} />
      </Box>
    </ScrollView>
  );
};
