import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, TouchableBox, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

type NewReferralBadgeProps = {};

export const NewReferralBadge: React.FC<NewReferralBadgeProps & BoxProps> =
  cell(boxProps => {
    const {t} = useTranslation();

    const {
      overview: {
        new: newFriends,
        conditions: {percentFormatted},
      },
    } = NewReferralOverviewResource.use({});

    const handlePress = () => {
      SHARED_ROUTER_SERVICE.navigate('InviteFriends');
    };

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px={8}
        py={4}
        backgroundColor="$attention-02"
        borderRadius={32}
        onPress={handlePress}
        testID="MINER_NEW_REFERRAL_BADGE"
        {...boxProps}
      >
        <Icon name="gift" color="$attention-01" size={20} />
        <Text variant="$body-02" color="$attention-01" ml={6}>
          {t('surface.top_bar.referral_badge', {percent: percentFormatted})}
        </Text>
        {newFriends > 0 && (
          <Box
            backgroundColor="$attention-01"
            borderRadius={32}
            px={4}
            py={1}
            ml={4}
            testID="NEW_REFERRAL_BADGE_COUNT"
          >
            <Text variant="$body-01-medium-accent" color="$text-04">
              +{newFriends}
            </Text>
          </Box>
        )}
      </TouchableBox>
    );
  });
