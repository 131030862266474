import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Link, RewardBox} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {type CoindropPerkDropItem} from '@youtoken/ui.resource-first-action-perks';
import {CoinIconsBig} from '../../../Coindrop';

type CoindropPromiseBlockProps = {
  coins: CoindropPerkDropItem[];
};

export const CoindropPromiseBlock: React.FC<CoindropPromiseBlockProps> =
  observer(({coins}) => {
    const {t} = useTranslation();

    if (coins.length === 0) {
      return null;
    }

    const coindropStringFormatted = React.useMemo(() => {
      const lastDrop = coins[coins.length - 1];

      const firstPart = coins
        .slice(0, -1)
        .map(coin => formatDrop(coin))
        .join(', ');
      const lastPart = formatDrop(lastDrop!);

      return `${firstPart} ${t('surface.fee_string.template.or')} ${lastPart}`;
    }, [coins]);

    const coindropTickersList = coins.map(coin => coin.ticker);

    return (
      <RewardBox
        title={t('surface.wallets.first_action_modal.block_coindrop.title')}
        iconComponentName="Icon"
        iconName="gift"
        iconColor="$success-01"
        iconBgColor="$success-02"
        borderColor="$success-03"
        text={`${t(
          'surface.wallets.first_action_modal.block_coindrop.subtitle_prefix'
        )} ${coindropStringFormatted} `}
      >
        <Box mt={16}>
          <CoinIconsBig coins={coindropTickersList} />
        </Box>
        <Box mt={16}>
          <Link
            url="https://help.youhodler.com/articles/9614953"
            variant="$body-02"
          >
            <Text color="$text-02">
              {t('agreement.youhodler.terms_and_conditions')}
            </Text>
          </Link>
        </Box>
      </RewardBox>
    );
  });

const formatDrop = ({
  ticker,
  amountPerDrop,
}: {
  ticker: string;
  amountPerDrop: number;
}) => {
  return `${amountPerDrop} ${ticker.toUpperCase()}`;
};
