import * as React from 'react';
import {observer} from 'mobx-react';
import {type TKey, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {type FirstActionType} from '@youtoken/ui.resource-first-action-perks';

type CongratsBannerProps = {
  type: FirstActionType;
};

export const CongratsBanner: React.FC<CongratsBannerProps & BoxProps> =
  observer(({type, ...boxProps}) => {
    const {t} = useTranslation();

    const i18nKeys: Record<FirstActionType, TKey> = {
      deposit: 'surface.wallets.first_action_modal.congrats_banner.deposit',
      trade: 'surface.wallets.first_actiom_modal.congrats_banner.trade',
    };

    return (
      <Box backgroundColor="$success-02" py={16} px={24} {...boxProps}>
        <Text
          textAlign="center"
          variant="$body-01-high-accent"
          color="$success-01"
        >
          {t(i18nKeys[type])}
        </Text>
      </Box>
    );
  });
