import * as React from 'react';
import {observer} from 'mobx-react';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MethodItemTag} from '../../../../components';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../../state';

export interface PayWithFieldProps {
  isActive?: boolean;
  onPress: () => void;
}

export const PayWithField: React.FC<PayWithFieldProps & BoxProps> = observer(
  ({onPress, isActive = false, ...boxProps}) => {
    const {t} = useTranslation();
    const {
      view: {currentDepositMethod},
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    if (!currentDepositMethod) {
      return null;
    }

    const {title, fee, description, icon, tags} = currentDepositMethod;

    return (
      <Box {...boxProps}>
        <Label>{t('ramp.conversion_form.label.pay_with')}</Label>
        <TouchableBox
          mt={10}
          px={24}
          py={16}
          flexDirection="row"
          borderWidth={1}
          borderRadius={6}
          borderColor="$ui-02"
          alignItems="center"
          onPress={onPress}
          testID="WIZARD_PAY_WITH_BUTTON"
        >
          {React.isValidElement(icon) && (
            <Box
              mr={16}
              width={48}
              height={48}
              borderRadius={24}
              backgroundColor={'$interactive-02'}
              alignItems="center"
              justifyContent="center"
            >
              {icon}
            </Box>
          )}
          <Box flexDirection="column" flexGrow={1} flexBasis={0}>
            <Text variant="$body-01-high-accent" color="$text-01">
              {title}
            </Text>
            {Boolean(fee) && (
              <Text mt={4} variant="$body-02" color="$text-02">
                {fee}
              </Text>
            )}
            {Boolean(description) && (
              <Text mt={4} variant="$body-02" color="$text-02">
                {description}
              </Text>
            )}
            {Boolean(tags?.length) && (
              <Box
                flexDirection="row"
                alignItems="center"
                flexWrap="wrap"
                pt={4}
                m={-2}
              >
                {tags!.map(name => (
                  <MethodItemTag key={name} name={name} m={2} />
                ))}
              </Box>
            )}
          </Box>
          <Box justifyContent="center">
            <Icon name={isActive ? 'collapse' : 'expand'} color="$text-02" />
          </Box>
        </TouchableBox>
      </Box>
    );
  }
);
