import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, type BoxProps, Box} from '@youtoken/ui.primitives';
import {FeaturesResource} from '@youtoken/ui.resource-features';
import {FirstActionPerksModal} from '../../../../FirstActionPerks';

export const TouchableWrapper: React.FC<BoxProps> = observer(
  ({children, ...boxProps}) => {
    const {isNewOnboardingEnabled} = FeaturesResource.use({});

    const [isOpen, setIsOpen] = React.useState(false);

    const handlePress = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    return isNewOnboardingEnabled ? (
      <>
        <TouchableBox {...boxProps} onPress={handlePress}>
          {children}
        </TouchableBox>
        <FirstActionPerksModal
          variant="deposit"
          isOpen={isOpen}
          onClose={close}
        />
      </>
    ) : (
      <Box {...boxProps}>{children}</Box>
    );
  }
);
