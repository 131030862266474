import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MinerFeature} from '@youtoken/ui.resources-miner';
import {Box} from '@youtoken/ui.primitives';
import {
  AccountLevelProgress,
  AccountLevelsMarketAnalytics,
} from '@youtoken/ui.surface-account-levels';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  HexGrid,
  GetMoreSparksModal,
  Overview,
  Levels,
  HowItWorksButton,
  GetMoreSparksModalTrigger,
  ReferralBanner,
} from '../components';
import {HowItWorksBanner} from './HowItWorksBanner';
import {ResetButton} from './ResetButton';

export const MinerSurface: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [sparksShort, setSparksShort] = React.useState(0);

  const {
    resources: {
      authMe: {
        data: {
          clientSettings: {hasSeenMinerUpgradeModalFirstTime},
        },
        newReferralEnabled,
        setHasSeenMinerUpgradeModalFirstTime,
      },
      loyalty: {
        data: {currentLevel},
      },
      minerOverview: {finalSparkBalance},
    },
  } = MinerFeature.use({});

  const openModal = React.useCallback(
    (miningPrice: number) => {
      if (currentLevel < 3) {
        SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
        return;
      }
      const sparksShortToMine = miningPrice - finalSparkBalance;
      setSparksShort(sparksShortToMine);
      setModalOpen(true);
    },
    [finalSparkBalance, setSparksShort, currentLevel, setModalOpen]
  );

  const handleNotEnoughSparks = React.useCallback(
    (miningPrice: number) => {
      openModal(miningPrice);
    },
    [openModal]
  );

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  React.useEffect(() => {
    if (currentLevel >= 3 && !hasSeenMinerUpgradeModalFirstTime) {
      setModalOpen(true);
      setHasSeenMinerUpgradeModalFirstTime();
    }
  }, []);

  return (
    <>
      <Box
        flex={1}
        mx={{default: 20, desktop: 0}}
        mt={{default: 16, desktop: 0}}
        borderColor="$ui-01"
        borderWidth={{default: 0, tablet: 1}}
        borderRadius={16}
        zIndex={1}
      >
        <Box
          alignItems="center"
          width="100%"
          p={{default: 0, tablet: 20}}
          pt={{default: 0, tablet: 16}}
          pb={{default: 16, tablet: 24}}
          borderColor="$ui-01"
        >
          {newReferralEnabled && <ReferralBanner mb={16} mt={-16} />}

          <Overview zIndex={1} px={0} />

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width={{default: 360, tablet: 380}}
            height={{default: 360, tablet: 380}}
          >
            <HexGrid onNotEnoughSparks={handleNotEnoughSparks} />
          </Box>

          <ResetButton mb={24} />

          <AccountLevelProgress
            width="100%"
            TradeToUpgrade={GetMoreSparksModalTrigger}
            borderWidth={1}
            mb={16}
          />

          <HowItWorksBanner width="100%" />
        </Box>
      </Box>

      <AccountLevelsMarketAnalytics
        mx={{default: 16, phone: 24, desktop: 0}}
        mt={{default: 16, phone: 24}}
      />

      <Levels />

      <Box
        width="100%"
        alignItems="center"
        justifyContent="center"
        mt={Platform.select({web: 32, native: 0})}
        mb={Platform.select({web: 32, native: 24})}
      >
        <HowItWorksButton />
      </Box>
      <GetMoreSparksModal
        title={t('surface.upgrade_modal.sparks.title')}
        isOpen={isModalOpen}
        onClose={closeModal}
        noticeText={
          sparksShort > 0
            ? t('surface.miner.unlock_blocks.need_more_sparks_to_mine', {
                count: sparksShort,
              })
            : undefined
        }
      />
    </>
  );
});
