import * as React from 'react';
import {Platform} from 'react-native';
import {Modal} from '@youtoken/ui.modal';
import {
  type FirstActionType,
  type TPerkItem,
} from '@youtoken/ui.resource-first-action-perks';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {CongratsBanner} from './components';
import {FirstActionPerksCongratsSurface} from './FirstActionPerksCongratsSurface';

type FirstActionPerksCongratsModalProps = {
  data: TPerkItem[];
  variant: FirstActionType;
  onClose: () => void;
};

export const FirstActionPerksCongratsModal: React.FC<
  FirstActionPerksCongratsModalProps
> = ({data: items, variant, onClose}) => {
  const isWeb = Platform.OS === 'web';

  const handleMinerPress = () => {
    onClose();
    SHARED_ROUTER_SERVICE.navigate('Miner');
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      shouldShowHeaderSeparator={false}
      testID="FIRST_ACTION_PERKS_CONGRATS_MODAL"
    >
      <CongratsBanner type={variant} mt={isWeb ? 40 : 0} />
      <FirstActionPerksCongratsSurface
        items={items}
        variant={variant}
        onGoToPortfolioPress={onClose}
        onGoToMinerPress={handleMinerPress}
        p={24}
        pt={0}
      />
    </Modal>
  );
};
