import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardBox} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {type CoindropPerkDropItem} from '@youtoken/ui.resource-first-action-perks';

type CoindropResultBlockProps = {
  coins: CoindropPerkDropItem[];
  onButtonPress: () => void;
};

export const CoindropResultBlock: React.FC<CoindropResultBlockProps> = observer(
  ({coins, onButtonPress}) => {
    const {t} = useTranslation();

    return (
      <RewardBox
        title={t('surface.wallets.first_action_modal.block_coindrop.title')}
        iconComponentName="Icon"
        iconName="gift"
        iconColor="$success-01"
        iconBgColor="$success-02"
        borderColor="$success-03"
      >
        <Box gap={16} width="100%">
          <Box gap={16} mt={16} justifyContent="space-between">
            {coins.map(({ticker, amountPerDrop}) => (
              <Box
                key={ticker}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box flexDirection="row" gap={12} alignItems="center">
                  <LogoColored name={ticker as LogoColoredIconName} size={32} />
                  <Text color="$text-01">{ticker.toUpperCase()}</Text>
                </Box>
                <Text variant="$body-01-medium-accent" color="$success-01">
                  {`+${amountPerDrop} ${ticker.toUpperCase()}`}
                </Text>
              </Box>
            ))}
          </Box>
          <Button
            onPress={onButtonPress}
            testID="FIRST_ACTION_PERKS_CONGRATS_COINDROP_RESULT_BUTTON"
          >
            {t(
              'surface.wallets.first_action_modal.block_coindrop.result.button'
            )}
          </Button>
        </Box>
      </RewardBox>
    );
  }
);
