import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {HowItWorksModal} from './HowItWorks/HowItWorksModal';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const HowItWorksModalTrigger: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <TouchableBox
        width={36}
        height={36}
        justifyContent="center"
        alignItems="center"
        onPress={() => setOpen(true)}
        {...boxProps}
      >
        <Icon name="question" size={20} color="$text-03" />
      </TouchableBox>

      <HowItWorksModal
        title={t('surface.miner.how_it_works')}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  );
});
