import * as React from 'react';
import AppleLogin from 'react-apple-login';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {Form, SubmitButton, makeForm} from '@youtoken/ui.form-elements';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {OrSeparator} from '@youtoken/ui.elements';
import {AppleAuthButton, GoogleAuthButton} from '@youtoken/ui.elements-smart';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {AppEnv, WebAppEnv} from '@youtoken/ui.env-utils';
import {useIsWebView} from '@youtoken/ui.hooks';
import {
  CountrySelect,
  EmailField,
  PasswordField,
  TermsField,
  ReferralCodeField,
} from './components';
import {SignUpForm} from './state';

type SignUpSurfaceProps = {
  allowReferralCode?: boolean;
  referralCode?: string;
  showTitle?: boolean;
  showSSO?: boolean;
  onChangeCountry?: (country: string) => void;
};

export const SignUpSurface: React.FC<SignUpSurfaceProps> = cell(
  ({
    allowReferralCode = true,
    referralCode,
    showTitle = true,
    showSSO = true,
    onChangeCountry,
  }) => {
    const {t} = useTranslation();

    const resources = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
    });

    const form = makeForm(
      () =>
        new SignUpForm(
          {
            referralCode: allowReferralCode ? referralCode : undefined,
          },
          resources
        )
    );

    const isWebView = useIsWebView();
    const isLedger = ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app'];

    return (
      <Form form={form}>
        <Box
          flex={1}
          justifyContent={{tablet: 'flex-start', default: 'space-between'}}
          mb={{tablet: 0, default: -40}}
        >
          {showTitle && (
            <Box mb={32}>
              <Heading variant="$heading-01-responsive" mb={16}>
                {t('forms.sign_up.title')}
              </Heading>
              <Text variant="$body-01">{t('forms.sign_up.subtitle')}</Text>
            </Box>
          )}

          {showSSO && !isWebView && !isLedger && (
            <>
              <GoogleAuthButton
                residence={form.form.countryValue}
                inviteeCode={form.form.referralCodeValue}
                mb={12}
              />
              <AppleLogin
                clientId={
                  ENVIRONMENT.APP_ENV === AppEnv.production
                    ? 'com.youhodler.youhodler.login'
                    : 'com.youhodler.youhodler.dev.login'
                }
                redirectURI={`${ENVIRONMENT.BACKEND_URL}/auth/apple/callback`}
                responseMode="form_post"
                responseType="code id_token"
                scope="name email"
                render={renderProps => (
                  <AppleAuthButton
                    residence={form.form.countryValue}
                    inviteeCode={form.form.referralCodeValue}
                    {...renderProps}
                  />
                )}
              />
              <OrSeparator my={24} />
            </>
          )}

          <Box>
            <CountrySelect mb={12} zIndex={10} onChange={onChangeCountry} />
            <EmailField mb={24} />
            <PasswordField mb={24} />
            {allowReferralCode && <ReferralCodeField mb={24} />}
          </Box>

          <Box>
            <TermsField mb={24} />
            <SubmitButton size="large" mb={40} testID="SIGN_UP_BUTTON">
              {t('forms.sign_up.actions.sign_up')}
            </SubmitButton>
          </Box>
        </Box>
      </Form>
    );
  }
);
