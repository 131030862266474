import * as React from 'react';
import {observer} from 'mobx-react';
import {type TFuncKey} from 'react-i18next';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  Category,
  HODLsTariffsFeature,
  type TInstrumentsList,
} from '@youtoken/ui.resource-hodl-tariffs';
import {type BoxProps, TouchableBox, Text} from '@youtoken/ui.primitives';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

type CategoryFilterProps = {
  category: Category;
  list?: TInstrumentsList;
};

export const labelKeys: Record<Category, TFuncKey> = {
  [Category.NEW]: 'surface.hodls.portfolio.new.filter',
  [Category.POPULAR]: 'surface.hodls.portfolio.popular.filter',
  [Category.ROLLOVERS]: 'surface.hodls.portfolio.best_rollover_rates.filter',
};

const testIDs: Record<Category, string> = {
  [Category.NEW]: 'ALL_INSTRUMENTS_NEW_FILTER_BUTTON',
  [Category.POPULAR]: 'ALL_INSTRUMENTS_POPULAR_FILTER_BUTTON',
  [Category.ROLLOVERS]: 'ALL_INSTRUMENTS_ROLLOVERS_FILTER_BUTTON',
};

export const CategoryFilter: React.FC<CategoryFilterProps & BoxProps> =
  observer(({category, list, ...boxProps}) => {
    const {t} = useTranslation();
    const {activeCategoryFilter, instrumentsByCategory, toggleCategoryFilter} =
      HODLsTariffsFeature.use(list ? {list} : {});

    const isFilterActive = activeCategoryFilter === category;

    const handleFilterPress = React.useCallback(() => {
      DATA_LAYER.trackStrict('hodl-instruments-filtering', {category});
      toggleCategoryFilter(category);
    }, [category, activeCategoryFilter]);

    if (instrumentsByCategory[category].length === 0) {
      return null;
    }

    return (
      <TouchableBox
        py={6}
        px={12}
        borderRadius={6}
        backgroundColor={isFilterActive ? '$interactive-02' : '$ui-background'}
        testID={testIDs[category]}
        onPress={handleFilterPress}
        {...boxProps}
      >
        <Text
          variant="$body-02-medium-accent"
          color={isFilterActive ? '$text-05' : '$text-02'}
        >
          {t(labelKeys[category])}
        </Text>
      </TouchableBox>
    );
  });
