import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import type {BoxProps} from '@youtoken/ui.primitives';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {TradingInstruments} from '../../components';

const handleItemPress = () => {
  DATA_LAYER.trackStrict('hodl-open-instrument', {openedFrom: 'starred'});
};

export const StarredSection: React.FC<BoxProps> = observer(({...boxProps}) => {
  const {t} = useTranslation();
  const {starredInstruments: items} = HODLsTariffsFeature.use({});

  const handleShowAllOpen = React.useCallback((isExpand: boolean) => {
    if (isExpand) {
      DATA_LAYER.trackStrict('hodl-starred-show-all', {});
    }
  }, []);

  if (!items.length) return null;

  return (
    <TradingInstruments
      title={t('surface.hodls.portfolio.starred_title')}
      items={items}
      allowRolloversIcon
      onPressItem={handleItemPress}
      onPressShowAll={handleShowAllOpen}
      collapsedViewItemLimit={10}
      testID="STARRED_SECTION"
      {...boxProps}
    />
  );
});
