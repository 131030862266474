import {action, computed, observable} from 'mobx';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import * as yupPackage from '@youtoken/ui.yup';
import {
  handleGeneralErrorTranslated,
  messages,
} from '@youtoken/ui.validation-messages';
import {
  countriesList,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {type CardsFormArgs} from './types';
import {
  type FrameCardTokenizationFailEventProps,
  type FrameCardTokenizationSuccessEventProps,
} from '../types';

enum CardTokenizationErrorCodes {
  cardNumberInvalid = 'card_number_invalid',
  monthInvalid = 'card_expiry_month_invalid',
  yearInvalid = 'card_expiry_year_invalid',
  cvvInvalid = 'cvv_invalid',
  cardDataInvalid = 'request_body_malformed',
}

const COUNTRY_ITEMS = (() => {
  const checkoutCountryItems = getCountriesListLocalized(countriesList);

  return checkoutCountryItems.map(country => ({
    value: country.codeISO2,
    code: country.code,
    label: country.name,
    key: country.code,
  }));
})();

const nameRegExp = new RegExp(/^[a-zA-Z0-9\s]*$/);
const addressLineRegExp = new RegExp(/^[a-zA-Z0-9\s\-.,]*$/);
const zipCodeRegExp = new RegExp(/^[0-9a-zA-Z ]{0,10}$/);

export class Form {
  @observable
  public args: CardsFormArgs;

  @observable
  public instance: MobxReactForm;

  @observable
  public isValidCardNumber?: boolean = false;

  @observable
  public showCardNumberError?: boolean = false;

  @observable
  public isCardDataValid = false;

  @observable
  public isValidExpiryDate?: boolean = false;

  @observable
  public showExpiryDateError?: boolean = false;

  @observable
  public isValidCvv?: boolean = false;

  @observable
  public showCvvError?: boolean = false;

  @computed
  public get countryItems() {
    return COUNTRY_ITEMS;
  }

  @computed
  public get userAddress() {
    return AuthMeResource.getInstanceSafely({})?.data.address;
  }

  @computed
  public get defaultCountryCode() {
    const authData = AuthMeResource.getInstanceSafely({})?.data;

    if (
      this.userAddress?.country ||
      this.userAddress?.countryOfResidence ||
      authData?.residence
    ) {
      const countryItem = COUNTRY_ITEMS.find(
        item =>
          item.code === this.userAddress?.country ||
          item.label === this.userAddress?.countryOfResidence ||
          item.code === authData?.residence
      );

      if (countryItem) {
        return countryItem.value;
      }
    }

    return '';
  }

  @computed
  public get defaultCity() {
    const city = this.userAddress?.city || '';

    return city.match(addressLineRegExp) ? city : '';
  }

  @computed
  public get defaultZipCode() {
    const zipCode =
      this.userAddress?.postalCode || this.userAddress?.postCode || '';

    return zipCode.match(zipCodeRegExp) ? zipCode : '';
  }

  @computed
  public get defaultAddressLine() {
    const addressLine =
      this.userAddress?.streetAddress || this.userAddress?.address || '';

    return addressLine.match(addressLineRegExp) ? addressLine : '';
  }

  public constructor(args: CardsFormArgs) {
    this.args = args;

    const fields = {
      // NOTE: Main card data - most of the fields handled by checkout Frames library directly, so we have only cardToken from it in return
      cardToken: {value: ''},
      cardHolderName: {value: ''},
      // NOTE: Billing details fields
      countryCode: {value: this.defaultCountryCode},
      city: {
        value: this.defaultCity,
      },
      zipCode: {
        value: this.defaultZipCode,
      },
      addressLine1: {
        value: this.defaultAddressLine,
      },
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        if (
          !this.isValidCardNumber ||
          !this.isCardDataValid ||
          !this.isValidExpiryDate ||
          !this.isValidCvv
        ) {
          LOCAL_NOTIFICATIONS.error({
            text: i18n.t('validation.VALIDATION'),
          });
          this.instance.$('cardToken').set('value', '');
          // NOTE: just return because the errors come from FramesComponent and will be shown at this point
          return;
        }

        const {cardToken} = form.values();

        return TRANSPORT.API.post(`/v2/cards`, {
          provider: 'checkoutBankCard',
          data: {
            token: cardToken,
            storeCard: true,
          },
        })
          .then(() => {
            // NOTE: Attempt to refetch cards resource if it was called before to update the list of cards
            CardsResource.getInstanceSafely({})
              ?.refetch()
              .finally(() => this.args.onSuccess?.());
          })
          .catch(error => {
            this.instance.$('cardToken').set('value', '');
            handleFormSubmitError(this.instance, error);
          });
      },
      onError: (_: MobxReactForm) => {
        this.instance.$('cardToken').set('value', '');
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              cardToken: yup.string().required(messages.REQUIRED),
              cardHolderName: yup
                .string()
                .matches(nameRegExp, messages.IS_NOT_LATIN_SYMBOLS)
                .required(messages.REQUIRED),
              countryCode: yup.string(),
              city: yup.string(),
              zipCode: yup
                .string()
                .matches(zipCodeRegExp, i18n.t('validation.CODE_INVALID')),
              addressLine1: yup.string(),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @action
  private showFramesFieldsErrors = () => {
    this.showCardNumberError = true;
    this.showExpiryDateError = true;
    this.showCvvError = true;
  };

  @action
  public onChangeCardHolderName = (value: string) => {
    this.instance.$('cardHolderName').set('value', value);
  };

  @action
  public onCardTokenizationFail = (
    eventData: FrameCardTokenizationFailEventProps
  ) => {
    if (!eventData.error_codes?.length) {
      if (
        !this.isValidCardNumber ||
        !this.isCardDataValid ||
        !this.isValidExpiryDate ||
        !this.isValidCvv ||
        Boolean(this.instance.$('cardHolderName').error)
      ) {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('validation.VALIDATION'),
        });
      } else {
        handleGeneralErrorTranslated(eventData);
      }
    } else {
      eventData.error_codes.forEach(errorCode => {
        switch (errorCode) {
          case CardTokenizationErrorCodes.monthInvalid:
          case CardTokenizationErrorCodes.yearInvalid:
            this.isValidExpiryDate = false;
            break;
          case CardTokenizationErrorCodes.cvvInvalid:
            this.isValidCvv = false;
            break;
          case CardTokenizationErrorCodes.cardNumberInvalid:
            this.isValidCardNumber = false;
            break;
          case CardTokenizationErrorCodes.cardDataInvalid:
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
            break;
          default:
            handleGeneralErrorTranslated(eventData);
        }
      });
    }

    // NOTE: manual error handling for the Checkout Frames fields + triggering the validation of form fields by submit action
    this.showFramesFieldsErrors();
    // NOTE: preventDefault is required avoid conflict with submitting Frames form inside MobxReactForm
    this.instance.onSubmit({
      preventDefault: () => {},
    });
  };

  @action
  public onCardTokenizationSuccess = (
    e: FrameCardTokenizationSuccessEventProps
  ) => {
    // NOTE: It's preventing multiple submissions
    if (this.instance.$('cardToken').value) {
      return;
    }

    DATA_LAYER.trackStrict('card-save-submit', {provider: 'checkout'});

    this.instance.$('cardToken').set('value', e.token);

    // NOTE: preventDefault is required avoid conflict with submitting Frames form inside MobxReactForm
    this.instance.onSubmit({
      preventDefault: () => {},
    });
  };
}
