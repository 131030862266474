import * as React from 'react';
import {observer} from 'mobx-react';
import {Modal} from '@youtoken/ui.modal';
import {type FirstActionType} from '@youtoken/ui.resource-first-action-perks';
import {FirstActionPerksSurface} from './FirstActionPerksSurface';
import {useTranslation} from '@youtoken/ui.service-i18n';

type FirstActionPerksModalProps = {
  variant: FirstActionType;
  footerButtonLabel?: string;
  onFooterButtonPress?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const FirstActionPerksModal: React.FC<FirstActionPerksModalProps> =
  observer(
    ({variant, footerButtonLabel, onFooterButtonPress, isOpen, onClose}) => {
      const {t} = useTranslation();

      const title =
        variant === 'deposit'
          ? t('surface.wallets.first_action_modal.deposit.title')
          : t('surface.wallets.first_action_modal.trade.title');

      const subtitle =
        variant === 'deposit'
          ? t('surface.wallets.first_action_modal.subtitle_deposit_at_least', {
              amount: 20,
            })
          : t('surface.wallets.first_action_modal.trade.subtitle');

      return (
        <Modal
          title={title}
          subtitle={subtitle}
          isOpen={isOpen}
          onClose={onClose}
          shouldShowHeaderSeparator={false}
          testID="FIRST_ACTION_PERKS_MODAL"
        >
          <FirstActionPerksSurface
            variant={variant}
            footerButtonLabel={footerButtonLabel}
            onFooterButtonPress={onFooterButtonPress}
            p={24}
            pt={0}
          />
        </Modal>
      );
    }
  );
