import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const CongratsTitleWithIcon: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box alignItems="center">
      <Icon name="success_reward" size={88} color="$success-01" />
      <Heading variant="$heading-02">
        {t('surface.wallets.first_action_modal.congrats_text')}
      </Heading>
    </Box>
  );
});
