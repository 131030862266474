import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  transaction,
} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import Big from 'big.js';
import {notificationAsync, NotificationFeedbackType} from 'expo-haptics';
import {Platform} from 'react-native';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  MinerBlockData,
  MinerOverviewResponse,
  HexCoords,
} from './MinerOverviewResponse';
import type {
  MinerOverviewResourceArgs,
  BEBlockState,
  FEBlockState,
  IBlockBackendResponse,
  IBlocksBackendResponse,
  IMinerOverviewBackendResponse,
} from './types';
import {createEmptyDisabledBlock, hydrateDisabledBlocks} from './utils';
import {invariant} from '@youtoken/ui.utils';

const handleError = (error: Error) => {
  const _error = extractErrorFromResponse(error, '_error');
  const _errorTranslated = getTranslatedValidationMessage(_error);

  LOCAL_NOTIFICATIONS.error({
    text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
  });
};

export class MinerOverviewResource extends createResource<
  MinerOverviewResourceArgs,
  MinerOverviewResponse
>({
  getKey: () => `minerOverview`,
  getData: () => {
    return TRANSPORT.API.get<IMinerOverviewBackendResponse>(
      `/v1/miner/overview`
    ).then(res => {
      invariant(
        res?.data?.blocks,
        `MinerOverviewResource: blocks should be present in the response, ${JSON.stringify(
          res.data
        )} received.`
      );

      const hydratedBlocks = hydrateDisabledBlocks(res.data.blocks);

      res.data.blocks = hydratedBlocks;

      return deserialize(MinerOverviewResponse, res.data);
    });
  },
  skipRefreshOnVisible: false,
  // refetchInterval: 1000 * 60 * 1, // 1 minute
}) {
  _disposers: any[] = [];

  constructor(args: MinerOverviewResourceArgs, data: MinerOverviewResponse) {
    super(args, data);
    this._disposers.push(
      reaction(
        () => {
          return this.isDataObserved;
        },
        isDataObserved => {
          if (isDataObserved) {
            this.subscribeToSocketEvents();
          } else {
            this.unsubscribeFromSocketEvents();
          }
        }
      )
    );

    TRANSPORT.SOCKET.on('minerSparkBalance', this.reactToMinerSparkBalance);
    TRANSPORT.SOCKET.on('minerBlocks', this.reactToMinerBlocks);
    TRANSPORT.SOCKET.on(
      'minerTransactionBalance',
      this.reactToTransactionBalance
    );
  }

  onDestroy(): void {
    TRANSPORT.SOCKET.off('minerSparkBalance', this.reactToMinerSparkBalance);
    TRANSPORT.SOCKET.off('minerBlocks', this.reactToMinerBlocks);
    TRANSPORT.SOCKET.off(
      'minerTransactionBalance',
      this.reactToTransactionBalance
    );
    this._disposers.forEach((disposer: any) => disposer?.());
  }

  subscribeToSocketEvents() {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'miner',
    });
  }

  unsubscribeFromSocketEvents() {
    TRANSPORT.SOCKET.emit('unsub', {
      name: 'miner',
    });
  }

  @action reactToMinerSparkBalance = ({amount}: {amount: string}) => {
    // if there are pending expenses, don't update spark balance here
    // it is computed on REST responses
    if (!this._pendingExpenses) {
      this.data.sparkBalance = Number(amount);
    }
  };

  @action reactToMinerBlocks = (data: IBlocksBackendResponse) => {
    this.updateBlocks(data.blocks);
  };

  @action reactToTransactionBalance = ({
    amount,
    amountUSD,
  }: {
    amount: string;
    amountUSD: string;
  }) => {
    this.data.totalMinedAmount = new Big(amount);
    this.data.totalMinedAmountUSD = new Big(amountUSD);
  };

  @observable _pendingExpenses: number = 0;

  @computed
  public get timeLeftUntilNextFreeSparksDrop() {
    if (
      this.data.timeLeftUntilNextFreeSparksDrop &&
      this.data.timeLeftUntilNextFreeSparksDrop > 0
    ) {
      return this.data.timeLeftUntilNextFreeSparksDrop;
    }
    return null;
  }

  @computed
  public get finalSparkBalance() {
    return this.data.sparkBalance - this._pendingExpenses;
  }

  @computed
  public get totalMinedAmountFormatted(): string {
    return this.data.totalMinedAmount.toFixed(
      getCoinDecimalPrecision(this.data.totalMinedTicker)
    );
  }

  @computed
  public get totalMinedTickerFormatted(): string {
    return this.data.totalMinedTicker.toUpperCase();
  }

  @computed
  public get totalMinedAmountUSDFormatted(): string {
    return formatByTicker(this.data.totalMinedAmountUSD, 'usd');
  }

  @computed
  public get resetCost(): number {
    return this.data.resetCost;
  }

  @computed.struct
  public get blocks(): MinerBlockData[] {
    return this.data.blocks.slice().sort((a, b) => {
      return this.getBlockZIndex(a.id) - this.getBlockZIndex(b.id);
    });
  }

  @computed
  public get isAllBlocksClaimed(): boolean {
    return this.data.blocks.every(block => {
      const finalStatus = this.getBlockFinalStatus(block.id);

      return (
        finalStatus === 'DISABLED' ||
        finalStatus === 'CLAIMED_COLORED' ||
        finalStatus === 'CLAIMED_GREY' ||
        finalStatus === 'CLAIMED_INFO'
      );
    });
  }

  //#endregion blocks

  @observable
  _FEBlockStatuses: {[id: string]: FEBlockState | null} = {};

  // a queue to prevent race conditions when mining or claiming
  // several blocks in a row
  @observable
  actionQueue: {action: 'mine' | 'claim'; blockId: string}[] = [];

  //#region actions

  @action runNextFromQueue = () => {
    if (this.actionQueue.length === 0) {
      return;
    }

    const {action, blockId} = this.actionQueue[0]!;

    let handler =
      action === 'mine' ? this.startMiningRequest : this.startClaimingRequest;

    handler(blockId).finally(() => {
      runInAction(() => {
        this.actionQueue = this.actionQueue.slice(1);
        this.runNextFromQueue();
      });
    });
  };

  //#region mining

  @action startMining = (blockId: string) => {
    const block = this.findBlockById(blockId);

    invariant(
      block,
      `Block not found`,
      {blockId},
      {
        blockId,
        blocks: this.data.blocks,
      }
    );

    // needed to calculate current spark balance to show on FE
    // before the updated balance is received from BE
    this._FEBlockStatuses[block.id] = 'MINING_STARTING';
    this._pendingExpenses = this._pendingExpenses + block.miningPrice;

    this.actionQueue.push({action: 'mine', blockId});

    if (this.actionQueue.length === 1) {
      this.runNextFromQueue();
    }
  };

  @action startMiningRequest = (blockId: string) => {
    const block = this.findBlockById(blockId);

    invariant(
      block,
      `Block not found`,
      {blockId},
      {
        blockId,
        blocks: this.data.blocks,
      }
    );

    return TRANSPORT.API.post<IMinerOverviewBackendResponse>(
      `/v1/miner/start`,
      {
        blockId,
      }
    )
      .then(({data}) => {
        runInAction(() => {
          this.updateData(data);
        });
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => {
        runInAction(() => {
          // TODO: what
          this._FEBlockStatuses[block.id] = null;
          this._pendingExpenses = this._pendingExpenses - block.miningPrice;
        });
      });
  };

  @action onTimerEnd = (blockId: string) => {
    const block = this.findBlockById(blockId);

    if (!block) {
      this.refetch();
      return;
    }

    block.status = 'READY';
  };

  //#endregion mining

  //#region claiming

  @action startClaiming = (blockId: string) => {
    this._FEBlockStatuses[blockId] = 'CLAIMING_STARTING';

    this.actionQueue.push({action: 'claim', blockId});

    if (this.actionQueue.length === 1) {
      this.runNextFromQueue();
    }
  };

  @action startClaimingRequest = (blockId: string) => {
    const block = this.findBlockById(blockId);

    invariant(
      block,
      `Block not found`,
      {blockId},
      {
        blockId,
        blocks: this.data.blocks,
      }
    );

    return TRANSPORT.API.post<IMinerOverviewBackendResponse>(
      `/v1/miner/claim`,
      {blockId}
    )
      .then(({data}) => {
        runInAction(() => {
          this._FEBlockStatuses[block.id] = 'CLAIMING';
          this.updateData(data);

          this.hapticSuccess();
        });
      })
      .catch(error => {
        runInAction(() => {
          this._FEBlockStatuses[block.id] = null;

          handleError(error);
        });
      });
  };

  @action onClaimBounceEnd = (blockId: string) => {
    this._FEBlockStatuses[blockId] = 'CLAIMED_COLORED';
  };

  @action onClaimFlyOffEnd = (blockId: string) => {
    this._FEBlockStatuses[blockId] = null;
  };

  //#endregion claiming

  //#region info

  @action showClaimedInfo = (blockId: string) => {
    this._FEBlockStatuses[blockId] = 'CLAIMED_INFO';
  };

  @action onGreyBounceEnd = (blockId: string) => {
    this._FEBlockStatuses[blockId] = null;
  };

  //#endregion info

  @action resetMiner = () => {
    return TRANSPORT.API.post(`/v1/miner/reset`)
      .then(res => {
        transaction(() => {
          this._FEBlockStatuses = {};
          this._pendingExpenses = 0;
          this.updateData(res.data);
        });
      })
      .catch(error => {
        handleError(error);
      });
  };
  //#endregion actions

  //#region utils
  // TODO: add typing for BE rest and socket responses;
  @action updateData = (newData: IMinerOverviewBackendResponse) => {
    transaction(() => {
      this.data.timeLeftUntilNextFreeSparksDrop =
        newData.timeLeftUntilNextFreeSparksDrop;

      this.data.totalMinedAmount = new Big(newData.totalMinedAmount);
      this.data.totalMinedAmountUSD = new Big(newData.totalMinedAmountUSD);
      this.data.totalMinedTicker = newData.totalMinedTicker;
      this.data.sparkBalance = Number(newData.sparkBalance);
      this.updateBlocks(newData.blocks);
    });
  };

  // TODO: this is not a serialized response, just naked JSON from socket/BE
  @action updateBlock = (block: IBlockBackendResponse) => {
    const _block = this.findBlockByCoords(block.coordinates);

    if (!_block) {
      return;
    }

    _block.id = block.id;
    _block.status = block.status as BEBlockState;
    _block.miningPrice = block.miningPrice;
    _block.timeLeft = block.timeLeft;
    _block.earnAmount = new Big(block.earnAmount);
    _block.earnAmountTicker = block.earnAmountTicker;
  };

  @action updateBlocks = (newBlocks: IBlockBackendResponse[]) => {
    transaction(() => {
      newBlocks.forEach(newBlock => {
        if (newBlock.status === 'DISABLED') {
          const emptyBlock = createEmptyDisabledBlock(newBlock.coordinates);
          this.updateBlock(emptyBlock);
        } else {
          this.updateBlock(newBlock);
        }
      });
    });
  };

  findBlockById = computedFn((blockId: string) => {
    return this.data.blocks.find(block => block.id === blockId);
  });

  findBlockByCoords = computedFn(({q, r, s}: HexCoords) => {
    return this.data.blocks.find(
      block =>
        block.coordinates.q === q &&
        block.coordinates.r === r &&
        block.coordinates.s === s
    );
  });

  /** final status - FE status if we have one (for animations), otherwise the original status from BE */
  getBlockFinalStatus = computedFn((blockId: string) => {
    const block = this.findBlockById(blockId);

    if (!block) {
      return null;
    }

    const frontendStatus = this._FEBlockStatuses[block.id];

    if (frontendStatus) {
      return frontendStatus;
    }

    // shortcut for the resource instance;
    const {finalSparkBalance} = MinerOverviewResource.getInstance({});

    // there is no intermediate state, so we can use BE status
    // but we need to check if user has enough sparks
    if (block.status === 'AVAILABLE') {
      if (block.miningPrice > finalSparkBalance) {
        return 'AVAILABLE_NOT_ENOUGH_SPARKS';
      } else {
        return 'AVAILABLE_ENOUGH_SPARKS';
      }
    }

    if (block.status === 'CLAIMED') {
      return 'CLAIMED_GREY';
    }

    // just return BE status
    return block.status;
  });

  /** virtual `z-index` for blocks */
  getBlockZIndex = computedFn((blockId: string) => {
    const finalStatus = this.getBlockFinalStatus(blockId);

    if (finalStatus === 'CLAIMING') {
      return 4;
    }

    if (finalStatus === 'CLAIMED_INFO') {
      return 3;
    }

    if (
      finalStatus === 'CLAIMING_STARTING' ||
      finalStatus === 'MINING_STARTING'
    ) {
      return 2;
    }

    return 1;
  });

  hapticSuccess = async () => {
    Platform.select({
      ios: () => {
        notificationAsync(NotificationFeedbackType.Success);
      },
      default: () => {},
    })();
  };
  //#endregion utils
}
