import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  type TouchableBoxProps,
  TouchableBox,
  Text,
} from '@youtoken/ui.primitives';
import {ProfileIcon} from '@youtoken/ui.elements';

export const TopBarProfileButton: React.FC<TouchableBoxProps> = observer(
  touchableBoxProps => {
    const {t} = useTranslation();

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        {...touchableBoxProps}
      >
        <ProfileIcon width={28} height={28} />
      </TouchableBox>
    );
  }
);
