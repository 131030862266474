import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {
  type FirstActionType,
  FirstActionPerksResource,
} from '@youtoken/ui.resource-first-action-perks';
import {
  CoindropPromiseBlock,
  IncentivesPromiseBlock,
  MinerPromiseBlock,
  SavingsPromiseBlock,
} from './components';

type FirstActionPerksSurfaceProps = {
  variant: FirstActionType;
  footerButtonLabel?: string;
  onFooterButtonPress?: () => void;
};

export const FirstActionPerksSurface: React.FC<
  FirstActionPerksSurfaceProps & BoxProps
> = observer(
  ({variant, footerButtonLabel, onFooterButtonPress, ...boxProps}) => {
    const {data: items} = FirstActionPerksResource.use({action: variant});

    const nextLevel = {
      deposit: 2,
      trade: 3,
    }[variant];

    return (
      <Box {...boxProps}>
        <Box gap={20}>
          {items.map(({type, params}) => {
            switch (type) {
              case 'COINDROP':
                return <CoindropPromiseBlock coins={params.coins} />;

              case 'SPARKS':
                return <MinerPromiseBlock sparks={params.amount} />;

              case 'INCENTIVES':
                return <IncentivesPromiseBlock percent={params.percent} />;

              case 'SAVINGS':
                return (
                  <SavingsPromiseBlock
                    level={nextLevel}
                    maxApr={params.maxApr}
                    tickers={params.tickers}
                  />
                );

              default:
                return null;
            }
          })}
        </Box>

        {footerButtonLabel && onFooterButtonPress && (
          <Box mt={24}>
            <Button
              type="primary"
              size="large"
              onPress={onFooterButtonPress}
              testID="FIRST_ACTION_PERKS_FOOTER_BUTTON"
            >
              {footerButtonLabel}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);
