import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox} from '@youtoken/ui.primitives';
import {
  HODLsTariffsFeature,
  type TInstrumentsList,
} from '@youtoken/ui.resource-hodl-tariffs';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Icon} from '@youtoken/ui.icons';

type InstrumentsStarredFilterProps = {
  list?: TInstrumentsList;
};

export const InstrumentsStarredFilter: React.FC<InstrumentsStarredFilterProps> =
  observer(({list}) => {
    const {starredFilterIsActive, setStarredFilter} = HODLsTariffsFeature.use(
      list ? {list} : {}
    );

    const handleToggleFilter = React.useCallback(() => {
      DATA_LAYER.trackStrict('hodl-instruments-filtering', {
        category: 'starred',
      });
      setStarredFilter(!starredFilterIsActive);
    }, [starredFilterIsActive]);

    return (
      <TouchableBox
        p={4}
        borderRadius={6}
        backgroundColor={
          starredFilterIsActive ? '$interactive-02' : '$ui-background'
        }
        onPress={handleToggleFilter}
        testID="ALL_INSTRUMENTS_STARRED_FILTER_BUTTON"
      >
        <Icon
          name={starredFilterIsActive ? 'star_filled' : 'star'}
          color={starredFilterIsActive ? '$interactive-01' : '$text-02'}
          size={20}
        />
      </TouchableBox>
    );
  });
