import {computed} from 'mobx';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {OnboardingWidgetResponse} from './OnboardingWidgetResponse';
import type {ActionName} from './types';

export type {StepItemStatus, ActionName} from './types';
export type {CoindropPromoParams} from './OnboardingWidgetResponse';

export class OnboardingWidgetResource extends createResource({
  getKey: () => 'onboardingWidget',
  getData: () =>
    TRANSPORT.API.get('/v1/onboarding/widget').then(({data}) => {
      return deserialize(OnboardingWidgetResponse, data);
    }),
}) {
  @computed get steps() {
    return this.data.steps;
  }

  @computed get isCompleted() {
    return this.steps.every(step => step.status === 'FINISHED');
  }

  @computed get promo() {
    return this.data.promo;
  }

  @computed get activeStep() {
    return this.data.steps.find(step => step.status === 'ACTIVE');
  }

  @computed get statusForAction() {
    return this.activeStep?.params?.currentStatus;
  }

  @computed get currentVerificationCode() {
    return this.activeStep?.params?.currentCode;
  }

  @computed get actionName(): ActionName | undefined {
    if (this.statusForAction) {
      if (
        ['NOT_SET', 'NONE'].includes(this.statusForAction) &&
        this.currentVerificationCode === 'identity'
      ) {
        return 'VERIFY_IDENTITY_BUTTON';
      }

      if (
        ['IN_PROGRESS'].includes(this.statusForAction) ||
        (['NOT_SET', 'NONE'].includes(this.statusForAction) &&
          this.currentVerificationCode !== 'identity')
      ) {
        return 'CONTINUE_VERIFICATION_BUTTON';
      }

      if (['SEND', 'MANUAL_REVIEW'].includes(this.statusForAction)) {
        return 'DOCS_UNDER_REVIEW_BANNER';
      }

      if (
        ['DENY', 'ADDITIONAL_DOCS_REQUIRED', 'RE_VERIFY'].includes(
          this.statusForAction
        )
      ) {
        return 'VERIFICATION_FAILED_BANNER';
      }
    }

    if (this.activeStep?.type === 'DEPOSIT') {
      return 'TOP_UP_BALANCE_BUTTON';
    }

    // NOTE: here can be added default ActionName if it needed
    return undefined;
  }
}
