import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {type SelectItem, Select} from '@youtoken/ui.select';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoansListItemFeatureArgs, LoansListItemFeature} from '../../../state';
import {DetailedActionsAdditionalsProps} from '..';

export interface BriefActionsProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onChangeOpenedAdditionalActions?: (opened: boolean) => void;
  onPressPay?: (loan: LoansListItemFeatureArgs) => void;
  onPressCancel?: (loan: LoansListItemFeatureArgs) => void;
  onPressCloseNow?: DetailedActionsAdditionalsProps['onPressSetClosePrice'];
  onPressIncrease?: DetailedActionsAdditionalsProps['onPressIncrease'];
  onPressDecrease?: DetailedActionsAdditionalsProps['onPressDecrease'];
  onPressReopen?: DetailedActionsAdditionalsProps['onPressReopen'];
  onPressSetClosePrice?: DetailedActionsAdditionalsProps['onPressSetClosePrice'];
}

export const BriefActions: React.FC<BriefActionsProps> = observer(
  ({
    mode,
    id,
    onChangeOpenedAdditionalActions,
    onPressCloseNow,
    onPressIncrease,
    onPressDecrease,
    onPressSetClosePrice,
    onPressReopen,
    onPressPay,
    onPressCancel,
    ...rest
  }) => {
    const {t} = useTranslation();

    const {
      isOpening,
      quickActionEnabled,
      quickActionText,
      enabledCloseNow,
      enabledIncrease,
      enabledDecrease,
      enabledReopen,
      enabledSetClosePrice,
    } = LoansListItemFeature.use({
      mode,
      id,
    });

    const handlePressPay = React.useCallback(() => {
      onPressPay?.({id, mode});
    }, [onPressPay, id, mode]);

    const handlePressCancel = React.useCallback(() => {
      onPressCancel?.({id, mode});
    }, [onPressCancel, id, mode]);

    const handlePressQuickAction = React.useMemo(() => {
      return isOpening ? handlePressCancel : handlePressPay;
    }, [isOpening, handlePressPay, handlePressCancel]);

    const items = React.useMemo(() => {
      let items: SelectItem[] = [];

      if (enabledCloseNow) {
        items.push({
          value: 'close',
          icon: <Icon name="check" />,
          label: t('surface.loans.item.close_now'),
        });
      }

      if (enabledIncrease) {
        items.push({
          value: 'increase',
          icon: <Icon name="increase" />,
          label: t('surface.loans.item.increase'),
        });
      }

      if (enabledDecrease) {
        items.push({
          value: 'decrease',
          icon: <Icon name="extend" />,
          label: t('surface.loans.item.extend'),
        });
      }

      if (enabledReopen) {
        items.push({
          value: 'reopen',
          icon: <Icon name="reopen" />,
          label: t('surface.loans.item.reopen'),
        });
      }

      if (enabledSetClosePrice) {
        items.push({
          value: 'ftp',
          icon: <Icon name="ftp" />,
          label: t('surface.loans.item.ftp'),
        });
      }

      return items;
    }, [
      enabledCloseNow,
      enabledIncrease,
      enabledDecrease,
      enabledReopen,
      enabledSetClosePrice,
      t,
    ]);

    const handleSelect = React.useCallback(
      (value: string) => {
        if (value === 'close') {
          return onPressCloseNow?.({id, mode});
        }

        if (value === 'increase') {
          return onPressIncrease?.({id, mode});
        }

        if (value === 'decrease') {
          return onPressDecrease?.({id, mode});
        }

        if (value === 'reopen') {
          return onPressReopen?.({id, mode});
        }

        if (value === 'ftp') {
          return onPressSetClosePrice?.({id, mode});
        }
      },
      [
        id,
        mode,
        onPressCloseNow,
        onPressIncrease,
        onPressDecrease,
        onPressReopen,
        onPressSetClosePrice,
      ]
    );

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        {...rest}
      >
        <Button
          disabled={!quickActionEnabled}
          onPress={handlePressQuickAction}
          flexShrink={0}
          width={200}
          ml={20}
          size="small"
        >
          {t(quickActionText)}
        </Button>
        {items.length > 0 && (
          <Select
            size="small"
            items={items}
            onSelect={handleSelect}
            dropdownWidth={200}
            withPadding={false}
            withBorder={false}
            labelComponent={() => {
              return null;
            }}
            iconComponent={({open}) => {
              return (
                <Box flex={1} justifyContent="center" alignItems="center">
                  <Icon
                    name="actions"
                    color={open ? '$text-02' : '$text-03'}
                    size={16}
                  />
                </Box>
              );
            }}
            width={32}
          />
        )}
      </Box>
    );
  }
);
