import * as React from 'react';
import {observer} from 'mobx-react';
import {SortingTabs, type SortingTabItem} from '@youtoken/ui.sorting-tabs';
import {
  HODLsTariffsFeature,
  type TInstrumentsList,
} from '@youtoken/ui.resource-hodl-tariffs';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {type BoxProps} from '@youtoken/ui.primitives';

type InstrumentsSortingTabsProps = {
  list?: TInstrumentsList;
};

export const InstrumentsSortingTabs: React.FC<
  InstrumentsSortingTabsProps & BoxProps
> = observer(({list, ...boxProps}) => {
  const {sortingTabs, activeSortingTabValue, updateSorting} =
    HODLsTariffsFeature.use(list ? {list} : {});

  const handlePress = React.useCallback(
    (item: SortingTabItem) => {
      DATA_LAYER.trackStrict('hodl-instruments-sorting', {
        category: item.value,
        direction: item.direction,
      });
      updateSorting(item);
    },
    [updateSorting]
  );

  return (
    <SortingTabs
      items={sortingTabs}
      activeValue={activeSortingTabValue}
      onChange={handlePress}
      {...boxProps}
    />
  );
});
