import * as React from 'react';
import {observer} from 'mobx-react';
import {BoxProps, Box} from '@youtoken/ui.primitives';
import {i18n} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {ActionItem} from './ActionItem';

export const ActionsBar: React.FC<BoxProps> = observer(boxProps => {
  const {
    authMe: {
      enableHodl,
      depositBuyCryptoEnabled,
      checkProductAvailability,
      data: {hasEverDeposited},
    },
    wallets: {walletsTickersEquivalentAmountOrder},
    hodlsTariffs: {tariffsCreateMHExists},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
    hodlsTariffs: getResourceDescriptor(HodlsTariffsResource, {}),
  });

  const handleOpenDepositWizard = React.useCallback(() => {
    DATA_LAYER.trackStrict('action-button-click', {button: 'deposit'});
    SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
  }, []);

  const handleDepositBuyCrypto = React.useCallback(() => {
    DATA_LAYER.trackStrict('action-button-click', {button: 'buyCrypto'});

    if (!checkProductAvailability('depositBuyCrypto')) {
      return;
    }

    SHARED_ROUTER_SERVICE.navigate('CryptoBuy', {});
  }, [checkProductAvailability]);

  const handleOpenExchange = React.useCallback(() => {
    DATA_LAYER.trackStrict('action-button-click', {button: 'convert'});

    const fromTicker = walletsTickersEquivalentAmountOrder[0]!;

    SHARED_ROUTER_SERVICE.navigate('Exchange', {fromTicker});
  }, [walletsTickersEquivalentAmountOrder]);

  const handleOpenMH = React.useCallback(() => {
    DATA_LAYER.trackStrict('action-button-click', {button: 'trade'});
    SHARED_ROUTER_SERVICE.navigate('NewMultiHODL', {}, {});
  }, []);

  return (
    <Box flexDirection="row" justifyContent="space-around" {...boxProps}>
      <ActionItem
        title={i18n.t('surface.wallets.common.deposit_button')}
        iconName="fiat_deposit"
        withAccent={hasEverDeposited}
        disabled={false}
        onPress={handleOpenDepositWizard}
      />
      <ActionItem
        title={i18n.t('surface.wallets.buy_crypto.title')}
        iconName="card"
        withAccent={false}
        disabled={!depositBuyCryptoEnabled}
        onPress={handleDepositBuyCrypto}
      />
      <ActionItem
        title={i18n.t('surface.wallets.common.convert_button')}
        iconName="exchange"
        withAccent={false}
        disabled={Boolean(walletsTickersEquivalentAmountOrder[0])}
        onPress={handleOpenExchange}
      />
      <ActionItem
        title={i18n.t('surface.wallets.common.boost_button')}
        iconName="hodl"
        withAccent={false}
        disabled={!enableHodl || !tariffsCreateMHExists}
        onPress={handleOpenMH}
      />
    </Box>
  );
});
