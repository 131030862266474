import {computed, observable} from 'mobx';
import {list, object, primitive, optional, serializable} from 'serializr';
import Big from 'big.js';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {type BEBlockState} from './types';

/** Hex coords for a grid item */
export class HexCoords {
  @serializable(primitive())
  @observable
  q!: number;

  @serializable(primitive())
  @observable
  r!: number;

  @serializable(primitive())
  @observable
  s!: number;
}

/** signle grid item */
export class MinerBlockData {
  @serializable(primitive())
  @observable
  id!: string;

  @serializable(primitive())
  @observable
  timeLeft!: number;

  @serializable(primitive())
  @observable
  miningPrice: number = 0;

  @serializable(bigNumber())
  @observable
  earnAmount!: Big;

  @serializable(primitive())
  @observable
  earnAmountTicker?: string;

  @serializable(primitive())
  @observable
  status!: BEBlockState;

  @serializable(object(HexCoords))
  @observable
  coordinates!: HexCoords;

  @computed get earnAmountFormatted() {
    return formatByTicker(this.earnAmount, this.earnAmountTicker);
  }

  @computed get earnAmountTickerFormatted() {
    return this.earnAmountTicker?.toUpperCase();
  }
}

export class SettingsResponse {
  @serializable(number())
  @observable
  hodlSparksAmount!: number;

  @serializable(number())
  hodlSparksRequiredVolume!: number;

  @serializable(primitive())
  @observable
  hodlSparksTicker!: string;

  @computed get hodlSparkTickerFormatted() {
    return this.hodlSparksTicker.toUpperCase();
  }
}

export class MinerOverviewResponse {
  @serializable(primitive())
  @observable
  timeLeftUntilNextFreeSparksDrop!: number | null;

  @serializable(bigNumber())
  @observable
  totalMinedAmount!: Big;

  @serializable(primitive())
  @observable
  totalMinedTicker!: string;

  @serializable(bigNumber())
  @observable
  totalMinedAmountUSD!: Big;

  @serializable(number())
  @observable
  sparkBalance!: number;

  @serializable(number())
  @observable
  resetCost!: number;

  @serializable(object(SettingsResponse))
  @observable
  settings!: SettingsResponse;

  @serializable(list(object(MinerBlockData)))
  @observable
  blocks!: MinerBlockData[];
}
